<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> <div>渠道订单</div> </template>
    </HeaderBox>
    <div class="selects">
      <a-input class="input" v-model="subCode" placeholder="订单编号" />
      <a-select
        placeholder="请选择渠道方"
        v-model="channelName"
        @change="onSelectChnnel"
        class="input"
        allowClear
      >
        <a-icon slot="suffixIcon" type="caret-down" />
        <a-select-option :value="item.channelName" v-for="item in channelData" :key="item.id">{{item.channelName}}</a-select-option>
      </a-select>
      <a-input class="input" v-model="productName" placeholder="商品名称" />
      <!-- 结算状态 -->
      <a-select
        placeholder="结算状态"
        class="input"
        v-model="channelBilling"
        allowClear
      >
        <a-icon slot="suffixIcon" type="caret-down" />
        <a-select-option :value="1"> 已结算 </a-select-option>
        <a-select-option :value="0"> 未结算 </a-select-option>
      </a-select>
      <a-range-picker
        valueFormat="YYYY-MM-DD HH:mm:ss"
        @change="onSelectTime"
        :placeholder="['支付开始时间','支付结束时间']"
        showTime
        class="input"
      ></a-range-picker>
    </div>
    <div class="findItem">
      <a-button type="primary" @click='onSearch()'>搜索</a-button>
      <a-button @click="markVisible = true">批量标记渠道</a-button>
      <a-button @click="onExportOrder">勾选导出订单</a-button>
      <a-button @click="openBillModal">勾选结算订单</a-button>
    </div>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item,index)=>item.orderProductId"
        :scroll="{ x: 1500 }"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onChangeSingle,
          columnTitle: '选择',
          getCheckboxProps: (record) => ({
            props: {
              disabled: false,
            },
          }),
        }"
        :pagination="{
          total:total,
          current:pageNumber,  
          defaultPageSize:pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
        <template slot="footer">
          <a-checkbox
            @change="onChangeAll"
            :checked="isChecked"
            class="allSellect"
          >
            全选
          </a-checkbox>
        </template>
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template slot="channelBilling" slot-scope="text">
          <a-tag :color="text == 1 ? 'green' : 'red'">
            {{text == 1 ? '已结算' : '未结算'}}
          </a-tag>
        </template>
        <a-tag color="pink">pink</a-tag>
        <template slot="operation" slot-scope="item, row">
          <div class="btn_router_link">
            <a>-</a>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 批量标记渠道方 -->
    <a-modal
      title="批量标记渠道方"
      :visible="markVisible"
      @cancel="markVisible = false"
      :confirm-loading="loading"
      @ok="onBatchMark"
    >
      <div class="content">
        <div class="list">
          <span class="name must">渠道方：</span>
          <a-select
            placeholder="请选择渠道方"
            v-model="bathForm.channelCode"
            class="input"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option :value="item.channelCode" v-for="item in channelData" :key="item.id">{{item.channelName}}</a-select-option>
          </a-select>
        </div>
        <div class="list">
          <span class="name must">关联商品：</span>
          <a-select
            class="input"
            placeholder="请选择关联商品"
            :filter-option="filterOption"
            v-model="bathForm.productId"
            showSearch
            allowClear
          >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option v-for="item in courseData" :key="item.productId"
            :value="item.productId">{{item.productName}}</a-select-option>
          </a-select>
        </div>
        <div class="list">
          <span class="name">支付时间：</span>
          <a-range-picker
            valueFormat="YYYY-MM-DD HH:mm:ss"
            :placeholder="['支付开始时间','支付结束时间']"
            v-model="paytime"
            @change="onSelectPayTime"
            showTime
            class="input"
          ></a-range-picker>
        </div>
        <div class="list">
          <span class="name must">手机号：</span>
          <a-textarea
            class="input"
            v-model="bathForm.mobile"
            :rows="4"
            placeholder="请输入手机号，多个手机号用 , 分割"
          />
        </div>
        <div class="tips">
          <p>注：适用于已生成订单的批量标记</p>
          <p>手机号合并公式"=TEXTJOIN(",",TRUE,C2:C19)"</p>
        </div>
      </div>
    </a-modal>

    <!-- 确认结算订单 -->
    <a-modal
      title="确认结算订单"
      :visible="orderVisible"
      @cancel="orderVisible = false"
      :confirm-loading="loading"
      @ok="onSettOption"
    >
      <div class="content">
        <div class="list">
          <span class="name must">渠道方：</span>
          <a-input
            class="input"
            :disabled="true"
            v-model="tChannelBillingDTO.channelName"
            placeholder="请输入渠道方"
          />
        </div>
        <div class="list">
          <span class="name must">结算说明：</span>
          <a-input
            v-model="tChannelBillingDTO.billingInstructions"
            class="input"
            placeholder="请输入结算说明"
          />
        </div>
        <div class="list">
          <span class="name must">结算金额：</span>
          <a-input
            v-model="tChannelBillingDTO.billingAmount"
            class="input"
            placeholder="请输入结算金额"
          />
        </div>
        <div class="list">
          <span class="name must">经手人：</span>
          <a-input
            v-model="tChannelBillingDTO.handler"
            class="input"
            placeholder="请输入经手人"
          />
        </div>
        <div class="list">
          <span class="name must">结算时间：</span>
          <a-date-picker show-time class="input" valueFormat="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" v-model="tChannelBillingDTO.billingTime" placeholder="请选择结算时间" />
        </div>
        <div class="list">
          <span class="name">结算周期：</span>
          <a-date-picker 
            v-model="year" 
            :open='pickerShow'  
            @openChange="openChange" 
            @panelChange="panelChange" 
            @change="clearChange"
            placeholder="年度" 
            format="YYYY" 
            style="width:40%"
            class="picker" 
            mode="year" />
          <a-select
            placeholder="季度"
            v-model="quarter"
            style="width:40%"
            allowClear
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option :value="1"> 第一季度 </a-select-option>
            <a-select-option :value="2"> 第二季度 </a-select-option>
            <a-select-option :value="3"> 第三季度 </a-select-option>
            <a-select-option :value="4"> 第四季度 </a-select-option>
          </a-select>
        </div>
        <div class="list">
          <span class="name">结算材料：</span>
          <a-upload
            :remove="removeImg"
            list-type="picture-card"
            :fileList="fileList"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
            :beforeUpload="beforeUpload"
            :customRequest="customRequest"
          >
            <div v-if="fileList.length < 5">
              <a-icon :type="uploading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传凭证</div>
            </div>
          </a-upload>
        </div>
      </div>
    </a-modal>

    <!-- 标记失败手机号 -->
    <a-modal
      title="标记失败手机号"
      :visible="errorVisible"
      @cancel="errorVisible = false"
    >
      <div class="content">
        <a-textarea v-model="errorPhoneStr" :rows="4" />
      </div>
      <template slot="footer">
        <a-button @click="errorVisible = false">确认</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    fixed: "left",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名/机构名称",
    fixed: "left",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "子订单编号",
    align: "center",
    dataIndex: "subCode",
  },
  {
    title: "商品名称",
    align: "center",
    width: 230,
    dataIndex: "productName",
  },
  {
    title: "商品数量",
    align: "center",
    width: 100,
    dataIndex: "productCount",
  },
  {
    title: "订单实付",
    align: "center",
    dataIndex: "orderPrice",
  },
  {
    title: "子订单实付",
    align: "center",
    dataIndex: "payPrice",
  },
  {
    title: "退款金额",
    align: "center",
    width: 100,
    dataIndex: "refundPrice",
  },
  {
    title: "支付时间",
    align: "center",
    dataIndex: "paymentTime",
  },
  {
    title: "结算状态",
    align: "center",
    dataIndex: "channelBilling",
    scopedSlots: { customRender: "channelBilling" },
  },
  {
    title: "渠道",
    align: "center",
    dataIndex: "channelName",
  },
  // {
  //   title: "操作",
  //   align: "center",
  //   width: 100,
  //   scopedSlots: { customRender: "operation" },
  // },
];
import HeaderBox from "@/components/HeaderBox.vue";
export default {
  // 可用组件的哈希表
  components: {HeaderBox},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns,
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      subCode:'',
      channelName:undefined,
      productName:'',
      channelBilling: undefined,
      start:'',
      end:'',
      selectedRowKeys: [],
      markVisible:false,
      errorVisible:false,
      errorPhoneStr:'',
      orderVisible:false,
      uploading:false,
      fileList: [], //材料结合
      file: {},
      channelData:[],
      courseData:[], // 关联商品课程列表
      bathForm:{
        channelCode:undefined,
        productId:undefined,
        mobile:'',
        start:'',
        end:'',
      },
      paytime:[],
      tChannelBillingDTO:{
        channelCode:'',//	渠道 id
        channelName:'', // 渠道名称
        billingInstructions:'', // 结算说明
        billingAmount:undefined, //结算金额
        handler:'',//经手人
        billingTime:'',//结算时间
        billingQuarter:'', //结算周期
        billingFile:"", // 结算材料
        billingItems:[]
      },
      year: null,
      quarter:undefined,
      pickerShow:false,
      isChecked: false, 
      selectItemAll:[],
      billingItems:[],
    }
  },
  // 事件处理器
  methods: {
    moment,
    clearChange(e){
      this.year = null
    },
    panelChange(e){
      let time = moment(e).format("YYYY");
      this.year = time
      this.pickerShow = false
    },
    openChange(e){
      this.pickerShow = true
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;    
      this.getManageList();
    },
    // 选择渠道方
    onSelectChnnel(e){
      this.channelData.forEach(element => {
        if(element.channelName == e){
          this.tChannelBillingDTO.channelCode = element.channelCode
          this.tChannelBillingDTO.channelName = element.channelName
        }
      });
      this.selectedRowKeys = []
      this.billingItems = []
      this.selectItemAll = []
      this.isChecked = false
      this.onSearch();
    },
    onSearch() {
      this.pageNumber = 1
      this.getManageList();
    },
    // 关联课程快捷搜索
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 选择时间
    onSelectTime(date, dateString) {
      this.start = dateString[0]; // 开始日期
      this.end = dateString[1]; // 结束日期
    },
    onSelectPayTime(date, dateString){
      this.bathForm.start = dateString[0]; // 开始日期
      this.bathForm.end = dateString[1]; // 结束日期
    },
    onSelect() {
    },
    // 勾选导出订单
    onExportOrder(){
      if(!this.selectedRowKeys.length){
        return this.$message.warning('请勾选订单');
      }

      window.open(
        this.$config.target +
        "/hxclass-management/channel/channel/order/product/export/byIds?ids=" +
        encodeURIComponent(this.selectedRowKeys)
      );

      this.selectedRowKeys = []
      this.billingItems = []
    },
    // 批量结算订单
    openBillModal(){
      if(!this.channelName){
        this.$message.warning("请先筛选渠道方!");
        return
      }
      if(!this.selectedRowKeys.length){
        this.$message.warning("请勾选订单!");
        return
      }
      let check = false
      this.billingItems.forEach(element => {
        if(element.channelBilling == 1){
          check = true
        }
      });
      if(check){
        this.$message.warning("当前勾选订单中有已结算状态订单!");
        return
      }
      this.orderVisible = true
    },
    // 获取渠道方
    getChannelList(){
      this.$ajax({
        method: 'get',
        url: '/hxclass-management/channel/login/list',
        params: {
          pageNum: 1,
          pageSize: 100,
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.channelData = res.data.records
        }
      })
    },
    // 查询商品课程
    getProductCourse(){
      this.$ajax({
        url: "/hxclass-management/product-archives/manage/select-win",
        params: {
          noExam:1,
          page: 1,
          size: 1000,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.courseData = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取渠道订单列表
    getManageList() {
      this.loading = true
      this.$ajax({
        method: 'get',
        url: '/hxclass-management/channel/channel/order/product/list',
        params: {
          subCode: this.subCode,
          channelName: this.channelName,
          productName: this.productName,
          channelBilling: this.channelBilling,
          start: this.start,
          end: this.end,
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
          if(this.selectItemAll[this.pageNumber - 1] && ((this.selectItemAll[this.pageNumber - 1].length) == this.tableData.length)){
            this.isChecked = true;
          } else {
            this.isChecked = false;
          }
        }
      })
    },
    // 单选
    onChangeSingle(selectedRowKeys,e){
      this.selectItemAll[this.pageNumber - 1] = e
      if(this.selectItemAll[this.pageNumber - 1].length == this.tableData.length){
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
      this.funSelectedRowKeys()
    },
    //全选
    onChangeAll(e) {
      if (e.target.checked == true) {
        this.isChecked = true;
        this.selectItemAll[this.pageNumber - 1] = this.tableData
      } else {
        this.selectItemAll[this.pageNumber - 1] = []
        this.isChecked = false;
      }
      this.funSelectedRowKeys()
    },
    funSelectedRowKeys(){
      this.selectedRowKeys = []
      this.billingItems = []
      this.selectItemAll.forEach(element => {
        element.forEach(subItem => {
          this.selectedRowKeys.push(subItem.orderProductId)
          this.billingItems.push({
            orderProductId: subItem.orderProductId,
            productCount: subItem.productCount,
            productId: subItem.productId,
            productName: subItem.productName,
            channelBilling: subItem.channelBilling
          })
        });
      });
    },
    // 图片删除回调
    removeImg(e) {
      this.fileList.map((item, index) => {
        if (item.uid == e.uid) {
          this.fileList.splice(index, 1);
        }
      });
    },
    // 上传前格式校验
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },
    customRequest(info) {
      let _this = this;
      this.file = info.file;
      this.fileList.push(info.file);
      this.uploading = true;

      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(info.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          _this.fileList.forEach((item) => {
            if (item.uid == _this.file.uid) {
              item["url"] = url;
            }
          });
        } else {
          this.$message.error("上传失败");
        }
        this.uploading = false;
      });
    },
    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    // 批量标记
    onBatchMark(){
      if(!this.bathForm.channelCode){
        return this.$message.warning("请选择渠道方");
      }
      if(!this.bathForm.productId){
        return this.$message.warning("请选择关联商品");
      }
      if(!this.bathForm.mobile){
        return this.$message.warning("请输入手机号");
      }
      this.loading = true
      this.$ajax({
        method: 'POST',
        url: '/hxclass-management/channel/channel/order/product/mark',
        params: this.bathForm
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.getManageList();
          this.bathForm={
            channelCode:undefined,
            productId:undefined,
            mobile:'',
            start:'',
            end:'',
          }
          this.paytime = []
          this.markVisible = false
          if(res.data){
            this.errorVisible = true
            this.errorPhoneStr = res.data.toString()
          } else {
            this.$message.success("操作成功");
          }
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 批量结算订单
    onSettOption(){
      if(!this.tChannelBillingDTO.billingInstructions){
        return this.$message.warning("请输入结算说明");
      } else if(!this.tChannelBillingDTO.billingAmount){
        return this.$message.warning("请输入结算金额");
      } else if(!this.tChannelBillingDTO.handler){
        return this.$message.warning("请输入经手人");
      } else if(!this.tChannelBillingDTO.billingTime){
        return this.$message.warning("请选择结算时间");
      } else if (this.year && !this.quarter || !this.year && this.quarter) {
        return this.$message.warning("请确认结算周期数据是否完整");
      }

      this.tChannelBillingDTO.billingItems = this.billingItems // 所选订单结集合
      var imgStr = (this.fileList.length ? this.fileList.map(item => item.url).join(',') :undefined)
      this.tChannelBillingDTO.billingFile = (imgStr ? imgStr.toString() : undefined)
      this.tChannelBillingDTO.billingQuarter = (this.year ? this.year + '-' + this.quarter : undefined)
      this.loading = true
      this.$ajax({
        method: 'POST',
        url: '/hxclass-management/channel/billing',
        params: this.tChannelBillingDTO
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.orderVisible = false
          this.tChannelBillingDTO = {
            channelCode:'',//	渠道 id
            channelName:'', // 渠道名称
            billingInstructions:'', // 结算说明
            billingAmount:undefined, //结算金额
            handler:'',//经手人
            billingTime:'',//结算时间
            billingFile:"", // 结算材料
            billingQuarter:"",
            billingItems:[]
          }
          this.year = null
          this.quarter = undefined
          this.fileList = []
          this.selectedRowKeys = []
          this.billingItems = []
          this.getManageList();
        } else {
          this.$message.error(res.message);
        }
        this.loading = false
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getChannelList();
    this.getProductCourse();
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.selects {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-items: flex-end;
  .input{
    width: 220px;
    margin-right: 20px;
    margin-bottom: 16px;
  }
}
.findItem {
  display: flex;
  .ant-btn {
    margin-right: 24px;
  }
  .ant-btn:last-child {
    margin-right: 0;
  }
}
.content {
  margin: 10px auto;
  .add-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .list {
    display: flex;
    margin: 10px 0;
    padding-right: 30px;
    align-items: baseline;
    .name {
      display: block;
      flex-shrink: 0;
      width: 95px;
      margin-right: 5px;
      text-align: right;
      
    }
    .must{
      &::before{
        content: "*";
        color: red;
      }
    }
    .input {
      flex: 1;
    }
  }
  .tips{
    padding-left: 30px;
  }
}
</style>
